import React, { Fragment, useEffect } from "react"
import Navbar from "../components/Navbar"
import "../css/about.css"
import ReactPlayer from "react-player"
import Footer from "../components/Footer"
import Img1 from "../images/usluge1.jpg"
import Img2 from "../images/usluge2.jpg"
import Img3 from "../images/usluge3.jpg"

import Img1Min from "../images/Bck8-min.jpg"
import "aos/dist/aos.css"
import AOS from "aos"
import Layout from "../components/layout"
import ProgressiveImage from "react-progressive-image-loading"
import Head from "../components/head"

const Usluge = () => {
  useEffect(() => {
    // Aos
    AOS.init({
      once: true,
    })
  })
  return (
    <Layout>
      <Head title="Usluge" />
      <section className="services-section">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Proizvodne i uslužne mogućnosti</h1>
            </div>
          </div>
        </section>
        <section>
          <div className="columns is-multiline is-mobile " data-aos="fade-up">
            <div className="column  is-full ">
              <h3>Sečenje toplovaljanog, hladnovaljanog i pocinkovanog lima</h3>
              <ul>
                <li>
                  Linija za ravnanje i poprečno sečenje toplovaljane,
                  hladnovaljane i pocinkovane trake na table lima, debljine od
                  2-5 mm i širine do 1500 mm.
                </li>
                <li>
                  Linija za ravnanje i poprečno sečenje hladnovljane,
                  pocinkovane i aluminijumske trake na table lima, debljine od
                  0,4-2 mm i širine do 1500 mm.
                </li>
                <li>
                  Hidraulične makaze za sečenje limova debljine 2-5 mm i dužine
                  3 m, debljine 6-8 mm i dužine 3 m.
                </li>
              </ul>
            </div>
            <div className="column is-full has-background-light">
              <h3>Slitovanje</h3>
              <ul>
                <li>
                  Linija za podužno sečenje toplovanjane, hladnovljane i
                  pocinkovane trake na slitovane trake, debljine od 1,5-6 mm i
                  širine 46-1600 mm.
                </li>
                <li>
                  Linija za podužno sečenje hladnovljane i pocinkovane trake na
                  slitovane trake, debljine od 0.4-2 mm i širine 30-1250 mm.
                </li>
              </ul>
            </div>
            <div className="column is-full">
              <h3>Profilisanje TR lima</h3>
              <ul>
                <li>
                  Linija za trapezno profilisanje, profila 37/200 od pocinkovane
                  i bojene trake, debljine 0,5-0,8 mm i širine 1000-1250 mm.
                </li>
              </ul>
            </div>
            <div className="column is-full has-background-light">
              <h3>Proizvodnja cevi</h3>
              <ul>
                <li>
                  Linija za proizvodnju šavnih, okruglih cevi prečnika
                  fi17,3-38,1 mm, kvadratnih cevi dimenzija 13×13 – 30×30 mm i
                  pravugaonih cevi dimenzija 20×10 – 40×20 mm, debljine zida 1-2
                  mm i dužine 4-8 m.
                </li>
                <li>
                  Linija za proizvodnju šavnih, okruglih cevi prečnik afi
                  42,4-127 mm, kvadratnih cevi dimenzija 30×30 – 100×100 mm i
                  pravugaonih cevi dimenzija 40×20 – 150×50 mm, debljine zida
                  1,5-5 mm i dužine od 4-12 m.
                </li>
              </ul>
            </div>
            <div className="column is-full">
              <h3>Sečenje flahova</h3>
              <ul>
                <li>
                  Hidraulične makaze za sečenje flahova iz tv table debljine od
                  2- 8 mm, duzine 3m.
                </li>
                <li>
                  Linija ze sečenje flaha iz slitovane tv trake debljine od 2-
                  5mm, dužine 3m i širine od 20- 50 mm.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default Usluge
